import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import PageBaseModule from '@/store/page';
import store from '@/store';

import { periodsColumnTitles } from './tableTitles';
import { periodsDetailColumnTitles } from './tableDetailTitles';
import { periodsInfoColumnTitles } from './tablePeriodsInfoTitles';

import { prepareTablePeriods } from './tablePeriods';
import { prepareTablePeriodsDetail } from './tablePeriodsDetail';
import { prepareTablePeriodsInfo, TablePeriodsInfoInterface } from './tablePeriodsInfo';

import { getFinancePeriodList, getFinancePeriodDetail } from '@/api/financePeriods';

import { prepareFloatFormat, prepareMoneyFormat } from '@/lib/Utils';

import moment from 'moment';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { PeriodsTableInterface } from '@/interfaces/employeeFinance.interface';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'periodsFinance';

export interface PeriodInterface {
  end: string;
  isAttention: boolean;
  payout: number;
  payoutDate: string;
  shiftsCount: number;
  start: string;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PeriodsFinanceModule extends PageBaseModule {
  search: { id: string; value: string; data?: { fullName: string } } = { id: '', value: '' };

  partner = '';
  employeeName = '';
  isTest = false;

  periodsColumnTitlesTable: TableHeaderInterface[] = periodsColumnTitles;
  periodsDetailColumnTitlesTable: TableHeaderInterface[] = periodsDetailColumnTitles;
  periodsInfoColumnTitlesTable: TableHeaderInterface[] = periodsInfoColumnTitles;
  periodsTable: TableApiInterface = {} as TableApiInterface;
  periodsDetailTable: TableApiInterface = {} as TableApiInterface;
  periodsInfoTable: TableApiInterface = {} as TableApiInterface;
  periodsTotal = 0;
  periodsTitle = '';

  constructor(module: PeriodsFinanceModule) {
    super(module);
  }

  @Mutation
  SET_SEARCH(value: { id: string; value: string; data: { fullName: string } }) {
    this.search = value;
  }

  @Mutation
  SET_PARTNER(value: string) {
    this.partner = value;
  }

  @Mutation
  SET_PERIODS_TITLE(value: string) {
    this.periodsTitle = value;
  }

  @Mutation
  SET_PERIODS_TOTAL(value: number) {
    this.periodsTotal = value;
  }

  @Mutation
  SET_PERIODS_INFO_TABLE(value: TableApiInterface) {
    this.periodsInfoTable = value;
  }

  @Mutation
  SET_PERIODS_DETAIL_TABLE(value: TableApiInterface) {
    this.periodsDetailTable = value;
  }

  @Mutation
  SET_EMPLOYEE_NAME(value: string) {
    this.employeeName = value;
  }

  @Mutation
  SET_PERIODS_TABLE(value: TableApiInterface) {
    this.periodsTable = value;
  }

  @Mutation
  SET_IS_TEST(value: boolean) {
    this.isTest = value;
  }

  @Action({ rawError: true })
  setIsTest(value: boolean) {
    this.context.commit('SET_IS_TEST', value);
  }

  @Action({ rawError: true })
  setSearch(value: { id: string; value: string; data: { fullName: string } }) {
    this.SET_SEARCH(value);
  }

  @Action({ rawError: true })
  setPartner(value: string) {
    this.SET_PARTNER(value);
  }

  @Action({ rawError: true })
  getPeriodsTitle(periods: string[]) {
    if (periods.length) {
      const result = periods
        .filter((date: string) => date)
        .map((date: string) => {
          return moment(date).format('DD.MM.YYYY');
        })
        .join(' – ');

      this.setPeriodsTitle(result);
    }
  }

  @Action({ rawError: true })
  setPeriodsTitle(value: string) {
    this.context.commit('SET_PERIODS_TITLE', value);
  }

  @Action({ rawError: true })
  async getPeriodsDetail(params: { employeeId: string; date: string; partner: string }) {
    try {
      const result = await getFinancePeriodDetail({
        employeeId: params.employeeId,
        date: params.date,
        partner: params.partner,
      });

      const employeeName = [result.lastName, result.firstName, result.middleName].filter(Boolean).join(' ');

      this.setEmployeeName(employeeName);
      this.setIsTest(result.isTest);
      this.setPeriodsTableDetail(result.shifts);

      this.setPeriodsInfoTable({
        withholdingAgainstOverpaymentPastPeriod: result.withholdingAgainstOverpaymentPastPeriod,
        overpaymentRetentionNextPeriod: result.overpaymentRetentionNextPeriod,
        bonuses: result.bonuses,
        shortages: result.shortages,
      });

      this.setPeriodsInfo({
        payments: result.payments,
        retention: result.retention,
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setPeriodsInfo(value: { payments: number; retention: number }) {
    const total = prepareMoneyFormat(prepareFloatFormat(value.payments - value.retention).toString());

    this.context.commit('SET_PERIODS_TOTAL', total);
  }

  @Action({ rawError: true })
  setPeriodsInfoTable(table: TablePeriodsInfoInterface) {
    const tableInfo = prepareTablePeriodsInfo({
      table,
      columnTitles: this.periodsInfoColumnTitlesTable,
    });

    this.context.commit('SET_PERIODS_INFO_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  setPeriodsTableDetail(table: PeriodsTableInterface[]) {
    const tableInfo = prepareTablePeriodsDetail({
      table,
      columnTitles: this.periodsDetailColumnTitlesTable,
    });

    this.context.commit('SET_PERIODS_DETAIL_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  setEmployeeName(employee: string) {
    this.context.commit('SET_EMPLOYEE_NAME', employee);
  }

  @Action({ rawError: true })
  async getPeriodsTable() {
    try {
      if (!this.search.id) {
        this.context.commit('SET_PERIODS_TABLE', {});

        return;
      }

      const result = await getFinancePeriodList({ employeeId: this.search.id, partner: this.partner });

      this.setPeriodsTable(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setPeriodsTable(table: { payoutsAll: number; periods: PeriodInterface[] }) {
    const tableInfo = prepareTablePeriods({
      table: {
        periods: table.periods,
        payoutsAll: table.payoutsAll,
      },
      columnTitles: this.periodsColumnTitlesTable,
      employeeId: this.search.id,
    });
    this.context.commit('SET_PERIODS_TABLE', tableInfo);
  }
}

export default getModule(PeriodsFinanceModule);
