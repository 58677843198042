import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';

export const periodsDetailColumnTitles: TableHeaderInterface[] = [
  {
    id: 'date',
    name: 'Дата',
    visible: true,
  },
  {
    id: 'timeRange',
    name: 'Время',
    visible: true,
  },
  {
    id: 'shop',
    name: 'Объект',
    visible: true,
  },
  {
    id: 'workingOut',
    name: 'Выработка',
    visible: true,
    align: 'right',
  },
  {
    id: 'money',
    name: 'Заработано',
    visible: false,
  },
  {
    id: 'moneyTax',
    name: 'Заработано',
    visible: true,
    align: 'right',
  },
  {
    id: 'totalSum',
    name: 'Заработано',
    visible: false,
  },
];
