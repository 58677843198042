import { UITable } from '@/lib/util/tableUtils';
import { PeriodsTableInterface } from '@/interfaces/employeeFinance.interface';
import { TableTitle } from '@/lib/layouts/page/table.interface';

export const prepareTablePeriodsDetail = (params: { table: PeriodsTableInterface[]; columnTitles: TableTitle[] }) => {
  const uiTable = new UITable({
    rows: params.table,
    titles: params.columnTitles,
  });

  return uiTable.getTable();
};
