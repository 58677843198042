import { UITable } from '@/lib/util/tableUtils';
import moment from 'moment';
import { prepareFloatFormat, prepareMoneyFormat } from '@/lib/Utils';
import { TableTitle } from '@/lib/layouts/page/table.interface';

export interface TablePeriodsInfoInterface {
  bonuses: { sum: number; bonus: number }[];
  overpaymentRetentionNextPeriod: number;
  shortages: ShortageInterface[];
  withholdingAgainstOverpaymentPastPeriod: number;
}

interface ShortageInterface {
  step: number;
  stepAll: number;
  shortage: number;
  date: string;
  remnant: number;
  summa: number;
}

export const prepareTablePeriodsInfo = (params: { table: TablePeriodsInfoInterface; columnTitles: TableTitle[] }) => {
  const uiTable = new UITable({
    titles: params.columnTitles,
  });

  const prepareMoney = (money: number): string => {
    return prepareMoneyFormat(prepareFloatFormat(money).toString());
  };

  for (const key in params.table) {
    let title = '';
    let sum = 0;
    const value = (params.table as any)[key];

    if (key === 'withholdingAgainstOverpaymentPastPeriod') {
      if (value > 0) {
        title = 'Удержание в счёт переплаты в прошлом периоде';
        sum = value * -1;
      } else {
        title = 'Компенсация недоплаты за предшествующий период';
        sum = Math.abs(value);
      }
    }

    if (key === 'overpaymentRetentionNextPeriod') {
      if (value > 0) {
        title = 'Переплата. К удержанию в следующем периоде';
        sum = value;
      } else {
        title = 'Недоплата. К выплате в следующем периоде';
        sum = Math.abs(value) * -1;
      }
    }

    if (key === 'bonuses') {
      title = 'Премия';
      sum = value.reduce((sum: number, { summa: bonus }: { summa: number }) => sum + bonus, 0);
    }

    if (key === 'shortages') {
      value.forEach((shortage: ShortageInterface) => {
        uiTable.addRow({
          title: `
                        Частичное (${shortage.step} из ${shortage.stepAll}) удержание
                        в счёт недостачи на сумму -${prepareMoney(shortage.shortage)}
                        в смену от ${moment(shortage.date).format('DD.MM.YYYY')}.
                        Остаток к удержанию ${shortage.remnant ? '-' : ''}${prepareMoney(shortage.remnant)}
                    `,
          sum: shortage.summa * -1,
        });
      });

      continue;
    }

    uiTable.addRow({ title, sum });
  }

  return uiTable.getTable();
};
