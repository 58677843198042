import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';

export const periodsInfoColumnTitles: TableHeaderInterface[] = [
  {
    id: 'title',
    name: 'Доп. выплата / удержание',
    visible: true,
  },
  {
    id: 'sumTotal',
    name: 'Сумма',
    visible: true,
    align: 'right',
  },
  {
    id: 'sum',
    name: 'Сумма',
    visible: false,
  },
];
