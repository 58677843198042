/**
 * Global API for finance periods.
 *
 */

import { convertObjectToQueryParams } from '@/lib/Utils';
import API from '@/config/api-routers';
import { makeGetRequest } from './requests';

/**
 * Get finance periods list
 *
 * @param filters
 */
export const getFinancePeriodList = async (filters: { employeeId: string; partner?: string }) => {
  let url = API.financePeriods.getList.replace('$1', filters.employeeId);

  if (filters.partner) {
    url += `?partner=${filters.partner}`;
  }

  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get finance periods detail
 *
 * @param filters
 */
export const getFinancePeriodDetail = async (filters: { employeeId: string; date: string; partner: string }) => {
  let url = API.financePeriods.getDetail.replace('$1', filters.employeeId);
  const queryParams: Record<string, string> = { date: filters.date };

  if (filters.partner) {
    queryParams.partner = filters.partner;
  }

  url += `?${convertObjectToQueryParams(queryParams)}`;
  const result = await makeGetRequest(url);

  return result.data.result[0];
};
