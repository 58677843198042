import { UITable } from '@/lib/util/tableUtils';
import { PeriodInterface } from '@/store/finance/periods/index';
import { TableTitle } from '@/lib/layouts/page/table.interface';

interface TablePeriods {
  payoutsAll: number;
  periods: PeriodInterface[];
}

export const prepareTablePeriods = (params: {
  table: TablePeriods;
  columnTitles: TableTitle[];
  employeeId: number | string;
}) => {
  const uiTable = new UITable({
    rows: params.table.periods as any,
    titles: params.columnTitles,
  });

  return uiTable
    .setTotalData({
      totalSum: params.table.payoutsAll,
      employeeId: params.employeeId,
    })
    .getTable();
};
