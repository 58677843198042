import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';

export const periodsColumnTitles: TableHeaderInterface[] = [
  {
    id: 'period',
    name: 'Период',
    visible: true,
  },
  {
    id: 'start',
    name: 'От',
    visible: false,
  },
  {
    id: 'end',
    name: 'До',
    visible: false,
  },
  {
    id: 'status',
    name: 'Статус',
    visible: true,
  },
  {
    id: 'payoutDate',
    name: 'Дата выплаты',
    visible: false,
  },
  {
    id: 'payout',
    name: 'Заработано',
    visible: true,
    align: 'right',
  },
  {
    id: 'isAttention',
    name: 'Внимание',
    visible: false,
  },
  {
    id: 'link',
    name: 'Ссылка',
    visible: false,
  },
];
